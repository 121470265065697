import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import {
  AppealOposQuery,
  AppealOposQueryVariables,
  AppealViewFragment,
} from "generated/operations";
import { useAppealOposQuery } from "generated/hooks";
import { appealOposGridColumnTransformer } from "./utils/appealOposGridColumnTransformer";
import { GridSortModel } from "@mui/x-data-grid";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

export type TAppealOposDataGridProps = Partial<
  UseGqlDataGridParams<
    AppealOposQuery,
    AppealViewFragment,
    AppealOposQueryVariables
  >
>;

export type TAppealOposDataGridResult = UseGqlDataGridResult<
  AppealOposQuery,
  AppealViewFragment,
  AppealOposQueryVariables
>;

export const useAppealOposDataGrid = (
  props?: TAppealOposDataGridProps
): TAppealOposDataGridResult => {
  const gridData = useGqlDataGrid<
    AppealOposQuery,
    AppealViewFragment,
    AppealOposQueryVariables
  >({
    type: "appealOpos",
    queryHook: useAppealOposQuery,
    columnTransformer: appealOposGridColumnTransformer,
    selection: true,
    defaultSort: DEFAULT_SORT,
    ...props,
  });

  return gridData;
};
