import { EmployingOrganizationViewFragment } from "generated/operations";
import React, { FC, useState } from "react";
import { Grid, Label } from "@genome-frontend/uikit/build/lib/uikit";
import { EmployingOrganizationValues, Okved } from "../../types";
import { FieldArray, useFormikContext } from "formik";
import { locale } from "locale";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";
import { MaskExpress } from "utils/maskExpression";
import { SelectOkved } from "components/Selectors/SelectOkved";
import { Button } from "@mui/material";
import { useToasts } from "components/Toast";
import { EmployingOrganizationUpdateFormValidate } from "./EmployingOrganizationUpdateFormValidate";
import { SwitchField } from "components/formFields/SwitchField";
import { generatePassword } from "utils/generatePassword";
import { EmployingOrganizationEditDrawer } from "../EmployingOrganizationEditDrawer/EmployingOrganizationEditDrawer";

interface EmployingOrganizationUpdateFormProps {
  employingOrganization?: EmployingOrganizationViewFragment;
  isSubmitting?: boolean;
}

const emptyOkved: Okved = {
  id: undefined,
  name: "",
};

const maxAdditionalOkvedsNumber = 10;

export const EmployingOrganizationUpdateForm: FC<
  EmployingOrganizationUpdateFormProps
> = ({ employingOrganization, isSubmitting }) => {
  const [isOpenOrganizationUpdateDrawer, setIsOpenOrganizationUpdateDrawer] =
    useState(false);

  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<EmployingOrganizationValues>();

  const { addErrorsToast, addSuccessToast } = useToasts();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: EmployingOrganizationUpdateFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  const onGeneratePassword = () => {
    const ps = generatePassword(8);
    setFieldValue("password", ps);
  };

  const copyLoginClipboard = () => {
    const input = document.getElementById("textLoginId");
    //@ts-ignore
    navigator.clipboard.writeText(input!.value);
    addSuccessToast("Логин скопирован в буфер обмена");
  };

  const copyPasswordClipboard = () => {
    const input = document.getElementById("textPasswordId");
    //@ts-ignore
    navigator.clipboard.writeText(input!.value);
    addSuccessToast("Пароль скопирован в буфер обмена");
  };

  return (
    <>
      <Grid
        alignItems="flex-start"
        spacing={2}
        whiteSpace={"pre-wrap"}
        columnSpacing={2}
        container
      >
        <Grid xs={12} item>
          <h3>Данные уполномоченного представителя</h3>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.surname}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="delagateSurname"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.phoneNumber}:</Label>
          </Grid>
          <Grid xs={7} item>
            <MaskValidationTextInput
              mask={MaskExpress.phone}
              required
              name="phoneNumber"
              sx={{
                "& .MuiOutlinedInput-root": {
                  width: "223px",
                },
              }}
              {...validationFieldContext}
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.name}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="delagateName"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.email}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="email"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  width: "223px",
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.fathersName}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="delagateFathersName"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid xs={12} item>
          <h3>Данные об организации</h3>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>
              {locale.employingOrganizationUpdate.organizationName}:
            </Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="organizationName"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.address}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="address"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.mainOkved}:</Label>
          </Grid>
          <Grid xs={7} item>
            <SelectOkved
              validationFieldContext={validationFieldContext}
              name="mainOkved"
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.inn}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="inn"
              required
              type="number"
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label
              sx={{
                "& .MuiBox-root": {
                  whiteSpace: "wrap",
                },
              }}
            >
              {locale.organization.additionalOkveds}:
            </Label>
          </Grid>
          <FieldArray name="additionalOkveds" validateOnChange>
            {
              // @ts-ignore
              ({ push, remove }) => {
                const onAddOkvedButtonClick = () => {
                  if (
                    values.additionalOkveds!.length >= maxAdditionalOkvedsNumber
                  ) {
                    addErrorsToast(
                      [
                        {
                          message: `Указано максимальное количество дополнительных ОКВЭД'ов: ${maxAdditionalOkvedsNumber}`,
                        },
                      ],
                      ""
                    );
                    return;
                  }

                  push(emptyOkved);
                };

                return (
                  <React.Fragment>
                    {values.additionalOkveds!.map((okv, index) => (
                      <Grid
                        container
                        item
                        key={index}
                        spacing={2}
                        style={{ marginBottom: "5px" }}
                      >
                        <Grid item xs={10}>
                          <SelectOkved
                            validationFieldContext={validationFieldContext}
                            name={`additionalOkveds[${index}]`}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button fullWidth onClick={() => remove(index)}>
                            Удалить
                          </Button>
                        </Grid>
                      </Grid>
                    ))}

                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={onAddOkvedButtonClick}
                        style={{ marginTop: "5px" }}
                      >
                        Добавить ОКВЭД
                      </Button>
                    </Grid>
                  </React.Fragment>
                );
              }
            }
          </FieldArray>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.kpp}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="kpp"
              required
              type="number"
            />
          </Grid>
        </Grid>

        <Grid xs={6} container item>
          <Grid xs={5} item>
            <Label>{locale.employingOrganizationUpdate.ogrn}:</Label>
          </Grid>
          <Grid xs={7} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              name="ogrn"
              required
              type="number"
            />
          </Grid>
        </Grid>

        <Grid xs={12} item>
          <h3>Вход в систему</h3>
        </Grid>

        <Grid xs={12} container item>
          <Grid xs={2.5} item>
            <Label>{locale.employingOrganizationUpdate.login}</Label>
          </Grid>
          <Grid xs={3.5} item>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              id="textLoginId"
              required
              fullWidth
              name="login"
              disabled={true}
            />
            <Button onClick={copyLoginClipboard}>Копировать</Button>
          </Grid>
        </Grid>

        <Grid xs={12} container item>
          <Grid xs={2.5} item>
            <Label>{locale.employingOrganizationUpdate.password}</Label>
          </Grid>
          <Grid xs={3.5} item>
            <Button onClick={onGeneratePassword}>Сгенерировать пароль</Button>
            <ValidationTextInputField
              validationFieldContext={validationFieldContext}
              id="textPasswordId"
              required
              fullWidth
              name="password"
            />
            <Button onClick={copyPasswordClipboard}>Копировать</Button>
          </Grid>
        </Grid>

        <Grid xs={12} container item>
          <Grid xs={1} item>
            <SwitchField
              isOn={employingOrganization?.hasLoginPassAccess}
              name="hasLoginPassAccess"
            />
          </Grid>
          <Grid xs={7} item>
            <Label>
              {locale.employingOrganizationUpdate.hasLoginPassAccess}
            </Label>
          </Grid>
        </Grid>

        <Grid xs={12} container item>
          <Grid xs={1} item>
            <SwitchField
              isOn={employingOrganization?.isAccessBlocked}
              name="isAccessBlocked"
            />
          </Grid>
          <Grid xs={7} item>
            <Label>{locale.employingOrganizationUpdate.isAccessBlocked}</Label>
          </Grid>
        </Grid>
      </Grid>
      {isOpenOrganizationUpdateDrawer && (
        <EmployingOrganizationEditDrawer
          id={employingOrganization!.id}
          onSuccess={() => {
            setIsOpenOrganizationUpdateDrawer(false);
          }}
          onCancel={() => {
            setIsOpenOrganizationUpdateDrawer(false);
          }}
        />
      )}
    </>
  );
};
