import { CreateAppealOpoValues } from "../../types";
import { CreateAppealOpoInput } from "../../../../generated/operations";

export const getInputDataFromValues = (
  data: CreateAppealOpoValues,
  userId: string
): CreateAppealOpoInput => {
  return {
    employeePosition: data.employeePosition,
    department: data.department ?? "",
    userId: userId,
    requestDate: data.requestDate,
    okvedId: data.okved.id ?? 0,
    opoWorkTypeId: data.opoWorkType.id ?? 0,
    organizationInfoId: data.organizationInfo.id ?? 0,
  };
};
