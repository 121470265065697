import React, { FC, useState } from "react";
import { useClientQuery } from "generated/hooks";
import { ClientViewFragment } from "generated/operations";
import {
  Drawer,
  Typography,
  Grid,
  Label,
  DrawerContent,
  Box,
  Button,
  Tabs,
} from "@genome-frontend/uikit/build/lib/uikit";

import { locale } from "locale";
import { getFio } from "utils/getFio";
import { FormatDate } from "utils/formatDate";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { PencilOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PencilOutlineIcon";
import { useAuth } from "auth/useAuth";
import { AppealsGrid } from "pages/AppealPage/components/AppealsGrid/AppealsGrid";
import { AccessPermissions } from "types/AccessPermissions";
import { Outlet, useNavigate, useParams } from "react-router";
import { AppealView } from "pages/AppealPage/components/AppealView";
import { Breadcrumbs, Link } from "@mui/material";
import { paths } from "router/paths";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import "./ClientViewDrawer.css";
import { getSexName } from "utils/getSexName";
import { useAssignClientToEmployingOrganization } from "../../hooks/useAssignClientToEmployingOrganization";
import { AssignClientToEmployingOrganizationValue } from "../../types";
import { CreateAppealOpoDrawer } from "../CreateAppealOpo/CreateAppealOpoDrawer";
import { Tab } from "@genome-frontend/uikit/build/lib/uikit/Tabs";
import { TabPanel } from "components/TabPanel/TabPanel";
import { AppealOposGrid } from "pages/AppealPage/components/AppealOpoGrid/AppealOposGrid";

export enum ViewFrom {
  EmployingOrganizationCreateClient,
  Default,
}

interface ClientViewDrawerProps {
  id: String;
  onClose: () => void;
  onError: () => void;
  onEdit: (user: ClientViewFragment) => void;
  onRemoveSuccess: () => void;
  viewFrom: ViewFrom;
}

export const ClientViewDrawer: FC<ClientViewDrawerProps> = ({
  id,
  onClose,
  onError,
  onEdit,
  viewFrom,
}) => {
  const { data, loading } = useClientQuery({
    variables: {
      id,
    },
    onError: () => {
      onError();
    },
  });

  const navigate = useNavigate();
  const { assignClient } = useAssignClientToEmployingOrganization({
    onSuccess: () => navigate(paths.clients()),
  });

  const getContentDrawerProps = () => {
    switch (viewFrom) {
      case ViewFrom.Default:
        return {
          isWithActions: false,
        };

      case ViewFrom.EmployingOrganizationCreateClient:
        const emplOrgId = TokenHelper.getCurrentEmployingOrganizationId();
        if (emplOrgId === null) return {};
        const cancelProps = {
          onClick: onClose,
          children: "Отмена",
        };
        const addClientProps = {
          onClick: async () => {
            const assignDto: AssignClientToEmployingOrganizationValue = {
              clientId: id,
              employingOrganizationId: emplOrgId,
            };
            await assignClient(assignDto);
          },
          children: "Добавить пользователя",
        };
        return {
          isWithActions: true,
          cancelButtonProps: cancelProps,
          confirmButtonProps: addClientProps,
        };
    }
  };

  const { hasAccessPermission } = useAuth();
  const params = useParams();
  const appealId: number = Number(params.appealId);
  const appealName: string = String(params.appealName);
  const isAppealView: boolean = params.appealId !== undefined;

  const roleName = TokenHelper.getCurrentUserRole();
  const isViewAppeals: boolean =
    roleName === UserRole.Doctor ||
    roleName === UserRole.Therapist ||
    roleName === UserRole.LawOfficial;

  const closeHandler = () => {
    onClose();
  };

  const editHandler = () => {
    if (data?.client) {
      onEdit(data.client);
    }
  };

  const [currentTab, setCurrentTab] = useState<number>(0);

  if (!loading && !data) {
    return null;
  }

  const getInfoView = () => {
    return (
      <Grid spacing={2} container>
        {data?.client && (
          <>
            <Grid xs={12} item>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" href={paths.clients()}>
                  К списку пользователей
                </Link>
                {!isAppealView && isViewAppeals && (
                  <Label>Заявки пользователя</Label>
                )}
                {isAppealView && (
                  <Link underline="hover" href={paths.client(id.toString())}>
                    Заявки пользователя
                  </Link>
                )}
                {appealName && isAppealView && <Label>{appealName}</Label>}
              </Breadcrumbs>
            </Grid>

            <Grid xs={4} item>
              <Label>{locale.user.surname}</Label>
              <Typography>{data?.client.surname}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Label>{locale.user.name}</Label>
              <Typography>{data?.client.name}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Label>{locale.user.fathersName}</Label>
              <Typography>{data?.client.fathersName}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Label>{locale.user.sex}</Label>
              <Typography>{getSexName(data?.client.sex)}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Label>{locale.user.snils}</Label>
              <Typography>{data?.client.snils}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Label>{locale.user.birthdate}</Label>
              <FormatDate date={data?.client?.birthdate} />
            </Grid>
            <Grid xs={4} item>
              <Label>{locale.user.phoneNumber}</Label>
              <Typography>{data?.client.phoneNumber}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Label>{locale.user.email}</Label>
              <Typography>{data?.client.email}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const getAppealOpoView = () => {
    return (
      <>
        <Box className={"container"}>
          <CreateAppealOpoDrawer clientId={id.toString()} />
          <AppealOposGrid userId={id.toString()} />
        </Box>
      </>
    );
  };

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getEmployingOrganizationView = () => {
    return (
      <>
        <Tabs sx={{ mb: "20px" }} value={currentTab} onChange={handleChangeTab}>
          <Tab label="Персональные данные" />
          <Tab label="Заявки на услуги" />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          {getInfoView()}
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {getAppealOpoView()}
        </TabPanel>
      </>
    );
  };

  const isEmployingOrganization =
    TokenHelper.getCurrentUserRole() === UserRole.EmployingOrganization;

  return (
    <>
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 920,
          },
        }}
        open
        onClose={closeHandler}
      >
        <DrawerContent
          onClose={closeHandler}
          title={data?.client ? getFio(data.client, true) : ""}
          {...getContentDrawerProps()}
        >
          {hasAccessPermission(AccessPermissions.ClientsUpdate) && (
            <Box className={"box_header"}>
              <Box className={"box_center"}></Box>
              <Box className={"box_center"}>
                <Button
                  sx={{ ml: 1 }}
                  startIcon={<PencilOutlineIcon />}
                  onClick={editHandler}
                >
                  {locale.EDIT}
                </Button>
              </Box>
            </Box>
          )}
          {loading ? (
            <CenteredCircularProgress />
          ) : (
            <>
              {isEmployingOrganization ? (
                getEmployingOrganizationView()
              ) : (
                <>
                  {getInfoView()}
                  {!isAppealView && isViewAppeals && (
                    <Box className={"container"}>
                      <AppealsGrid id={id.toString()} />
                    </Box>
                  )}
                  {isAppealView && isViewAppeals && (
                    <Box className={"container"}>
                      <AppealView
                        clientId={id.toString()}
                        appealId={appealId}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </DrawerContent>
      </Drawer>
      <Outlet />
    </>
  );
};
