import { Outlet } from "react-router";
import React, { FC, useMemo } from "react";
import { TreeDataGrid } from "@genome-frontend/uikit/build/lib/uikit/TreeDataGrid/TreeDataGrid";
import { useAppealOposByEmployingOrganizationDataGrid } from "pages/AppealPage/hooks/useAppealOposByEmployingOrganization/useAppealOposGridByEmployingOrganization";

export interface TAppealOposByEmployingOrganizationGridProps {
  appealId: number;
}

export const AppealOposGridByEmployingOrganization: FC<
  TAppealOposByEmployingOrganizationGridProps
> = ({ appealId }) => {
  const gridData = useAppealOposByEmployingOrganizationDataGrid({
    variablesTransformer: (variables) => {
      variables.id = appealId;
      return variables;
    },
  });
  const columns = gridData.gridProps.columns.concat([]);
  const columnsMemo = useMemo(() => columns, [columns]);

  const { gridProps } = gridData;

  return (
    <>
      <TreeDataGrid
        {...gridProps}
        columns={columnsMemo}
        disableColumnFilter={true}
        rowsPerPageOptions={[10, 30, 50]}
        showOrderColumn
        showColumnSettings={false}
        checkboxSelection={false}
        // @ts-ignore
        childrenListName={"documents"}
      />
      <Outlet />
    </>
  );
};
