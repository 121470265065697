import { common } from "./common";
import { conclusion } from "./conclusion";
import { enums } from "./enums";
import { profile } from "./profile";
import { organization } from "./organization";
import { users } from "./users";
import { user } from "./user";
import { validation } from "./validation";
import { appeal } from "./appeal";
import { userChanges } from "./userChanges";
import { fileChanges } from "./fileChanges";
import { reports } from "./reports";
import { employingOrganization } from "./employingOrganization";
import { employingOrganizationUpdate } from "./employingOrganizationUpdate";
import { appealOpo } from "./appealOpo";

export const ruLocale = {
  ...common,
  enums,
  conclusion,
  profile,
  organization,
  employingOrganization,
  employingOrganizationUpdate,
  users,
  user,
  validation,
  appeal,
  appealOpo,
  userChanges,
  fileChanges,
  reports,
};
