import React, { useEffect, useRef } from "react";
import { TTextInputFieldProps } from "./TextInputField";
import { TValidationFieldContext } from "./base/ValidationFieldsContext";
import ReactInputMask, { Props } from "react-input-mask";
import { TextInput } from "@genome-frontend/uikit/build/lib/uikit";
import { useField } from "formik";
import { RussianRegionalCode } from "constants/russianPhoneNumber";

export type TMaskValidationTextInputProps = {
  phoneNumberRegionalCode?: string;
} & Props &
  TValidationFieldContext &
  TTextInputFieldProps;

export const MaskValidationTextInput = ({
  phoneNumberRegionalCode = RussianRegionalCode,
  ...props
}: TMaskValidationTextInputProps) => {
  const [field, meta, { setValue }] = useField(props.name);

  switch (props.name) {
    case "phoneNumber":
    case "phone":
      if (!String(field.value).startsWith(phoneNumberRegionalCode))
        field.value = `${phoneNumberRegionalCode}${field.value}`;
      break;
  }

  const validateSingleField = (value: any) => {
    const error = props.formValidationFunc({
      [props.name]: value,
    }) as any;
    props.setFieldError(props.name, error[props.name]);
  };

  const refInput: any = useRef(null);

  useEffect(() => {
    if (field.value != refInput.current.value) {
      setValue(refInput.current.value);
    }
  }, [refInput, field, setValue]);

  props.onChange = (e: any) => {
    const value = e.target.value;
    props.setFieldValue(props.name, value);
    props.setFieldTouched(props.name, true, false);
    validateSingleField(value);
  };
  props.onBlur = (e: any) => {
    const value = e.target.value;
    props.setFieldTouched(props.name, true);
    validateSingleField(value);
  };
  props.value = field.value ?? "";
  props.error = !!meta.error;
  props.helperText = props.touched[props.name] && props.errors[props.name];

  return (
    //@ts-ignore
    <ReactInputMask ref={refInput} {...props}>
      {(inputProps: any) => {
        return <TextInput {...inputProps} />;
      }}
    </ReactInputMask>
  );
};
